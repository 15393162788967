<template>
	<v-app>
		<v-container fluid fill-height>
			<v-row class="fill-height" no-gutters
				><v-col class="primary" cols="12" md="auto">
					<v-img
						src="/img/acara_logo_2.png"
						style="
							width: 70px !important;
							display: block;
							margin-left: 10px;
							margin-right: 10px;
							margin-top: 20px;
						"
						class=""
					/>
				</v-col>
				<v-col cols="12" md style="background: #eee" class="mt-0 pa-0">
					<v-card class="px-3 pl-6 mx-6 py-8">
						<v-row>
							<v-col cols="12" md="11">
								<h4>Solicitud</h4>
								<h1>Estado de Solicitud Nuevo Usuario Concesionario</h1>
							</v-col>
							<v-col cols="12" md="1">
								<v-img
									style="margin-left: 10px; margin-right: 10px; font-size: 36px"
									class="mdi mdi-bell"
								/>
							</v-col>
						</v-row>
					</v-card>

					<v-card class="px-3 pl-6 mx-12 py-8" style="font-size: 16px">
						<v-row>
							<table class="table">
								<tr>
									<td style="width: 150px">
										<p class="font-weight-bold my-1">N° Solicitud:</p>
									</td>
									<td style="width: 300px">
										<p class="ffont-weight-regular my-1">{{ editedItem.id }}</p>
									</td>
									<td style="width: 200px">
										<p class="font-weight-bold my-1">Nombre del Solicitante:</p>
									</td>
									<td style="width: 500px">
										<p class="ffont-weight-regular my-1">
											{{
												editedItem.datos_usuario.apellido +
												" " +
												editedItem.datos_usuario.nombre
											}}
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<p class="font-weight-bold my-1">Estado de Solicitud:</p>
									</td>
									<td>
										<p class="ffont-weight-regular my-1">
											<span
												class="rounded-pill pa-2 white--text"
												style="background-color: #0177bd"
												>{{ getDetalle(editedItem.estado_id, "estado") }}</span
											>
										</p>
									</td>
									<td>
										<p class="font-weight-bold my-1">Correo electrónico:</p>
									</td>
									<td>
										<p class="ffont-weight-regular my-1">
											{{ editedItem.datos_usuario.mail }}
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<p class="font-weight-bold my-1">Tipo de Solicitud:</p>
									</td>
									<td>
										<p class="ffont-weight-regular my-1">
											Solicitud Nuevo Usuario Concesionario
										</p>
									</td>
									<td>
										<p class="font-weight-bold my-1">
											Nombre del Concesionario:
										</p>
									</td>
									<td>
										<p class="font-weight-regular my-1">
											{{ editedItem.razon_social }}
										</p>
									</td>
								</tr>
								<tr>
									<td>
										<p class="font-weight-bold my-1">Fecha de Creación:</p>
									</td>
									<td>
										<p class="ffont-weight-regular my-1">
											{{ editedItem.created_at }}
										</p>
									</td>
									<td>
										<p class="font-weight-bold my-1">Dirección:</p>
									</td>
									<td>
										<p class="font-weight-regular my-1">
											{{
												editedItem.datos_entidad.calle +
												" " +
												editedItem.datos_entidad.numero +
												", " +
												editedItem.datos_entidad.ciudad.nombre +
												", " +
												editedItem.datos_entidad.partido.nombre +
												", " +
												editedItem.datos_entidad.provincia.nombre
											}}
										</p>
									</td>
								</tr>

								<tr>
									<td>
										<p class="font-weight-bold my-1">Teléfono Solicitante:</p>
									</td>
									<td>
										<p class="font-weight-regular my-1">
											{{ editedItem.datos_usuario.celular }}
										</p>
									</td>
									<td>
										<p class="font-weight-bold my-1">Teléfono Concesionario:</p>
									</td>
									<td>
										<p class="font-weight-regular my-1">
											{{ editedItem.datos_entidad.celular }}
										</p>
									</td>
								</tr>
							</table>
						</v-row>
					</v-card>

					<v-card class="px-3 pl-6 mx-12 py-8" style="font-size: 16px">
						<v-row>
							<v-col cols="12" md="12"
								><b>Comentario / Motivo</b><br />
								{{ editedItem.comentario }}</v-col
							>
						</v-row>
					</v-card>

					<v-card class="px-3 pl-6 mx-12 py-8 mb-12">
						<v-row>
							<v-col cols="12" md="1">
								<v-icon
									x-large
									style="
										width: 70px !important;
										display: block;
										margin-left: 30px;
										color: #0177bd;
									"
									>info</v-icon
								>
							</v-col>
							<v-col cols="12" md="11">
								<p>
									¿Tiene alguna duda o necesita asistencia adiciona?<br />
									Si tiene alguna pregunta o necesita ayuda con su solicitud, no
									dude en ponerse en contacto con nuestro equipo de soporte.<br />
									Puede enviar un correo electrónico a
									<a href="mailto:socios@acara.org.ar">socios@acara.org.ar</a>.
									Haga click en el enlace para redactar un correo directamente.
								</p></v-col
							>
						</v-row>
					</v-card>

					<div style="height: 100px"></div>
					<section
						class="contacto"
						style="
							padding: 12px 0 12px 0;
							background-color: #0177bd;
							color: #fff;
						"
					>
						<div class="container">
							<v-row>
								<v-col md="4" xs="12">
									<v-row>
										<v-col md="2"
											><img
												class="icon-size-2"
												src="https://acara.org.ar/img/icon_footer_phone.png"
										/></v-col>
										<v-col md="10">
											<p>Contactate con nosotros<br />(011) 5236 6500</p></v-col
										>
									</v-row>
								</v-col>
								<v-col md="4" xs="12">
									<v-row>
										<v-col md="2"
											><img
												class="icon-size-2"
												src="https://acara.org.ar/img/icon_footer_envelope.png"
										/></v-col>
										<v-col md="10">
											<p>
												Envianos tus consultas a <br /><a
													href="mailto:socios@acara.org.ar"
													style="color: #fff"
													>socios@acara.org.ar</a
												>
											</p></v-col
										>
									</v-row>
								</v-col>
								<v-col md="4" xs="12">
									<v-row>
										<v-col md="2"
											><img
												class="icon-size-2"
												src="https://acara.org.ar/img/icon_footer_map.png"
										/></v-col>
										<v-col md="10"
											><p>Encontranos<br />estés dónde estés</p></v-col
										>
									</v-row>
								</v-col>
							</v-row>
						</div>
					</section>
					<footer>
						<div
							class="terminos"
							style="
								padding: 12px 0 12px 0;
								background-color: #011f5b;
								color: #6db2d9;
							"
						>
							<div class="container">
								<div class="row">
									<div class="col-6 text-left ml-2">
										<p style="margin-bottom: 5px">Av Córdoba 3371, CABA</p>
										<p>2024 ACARA - Todos los derechos reservados.</p>
									</div>
								</div>
							</div>
						</div>
					</footer>
				</v-col>
			</v-row>
		</v-container>
	</v-app>
</template>

<script>
	function getTitle() {
		return "solicitud usuario";
	}

	export default {
		name: "solicitudUsuarioEstado",

		data: (vm) => ({
			valid: true,
			route: "solicitudUsuarioPublic",
			//dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			editedIndex: -1,
			editedItem: {
				id: "0",
				cuit: "",
				razon_social: "",
				estado_id: 1,
				datos_entidad: {},
				datos_usuario: {},
			},
			defaultItem: {
				cuit: "",
				razon_social: "",
				estado_id: 1,
				datos_entidad: {},
				datos_usuario: {},
			},

			estados: [
				{ id: 1, detalle: "PENDIENTE" },
				{ id: 2, detalle: "APROBADA" },
				{ id: 3, detalle: "RECHAZADA" },
			],
		}),

		computed: {},

		created() {
			this.$cv("colorPrimary");
		},

		methods: {
			async editItem(id) {
				let vm = this;
				//aca toque
				Swal.alertGetInfo("Obteniendo información <br><b></b>");

				await vm.$axiosApi
					.getById(vm.route, id)
					.then((r) => {
						//console.log(r.data);
						if (r.data.data) {
							this.editedItem = r.data.data;
							this.editedIndex = r.data.data.id;
							//this.showDialog = true;
							//this.dialog = true;
							console.log(this.editedItem);
							Swal.close();
						} else {
							Swal.close();
							Swal.alertError(
								"No se pudo recuperar la información",
								"intente nuevamente o comuniquese con el administrador"
							);
							this.$router.push({ path: "/unauthorized" });
						}
						//vm.snackbar = false;
					})
					.catch(function (error) {
						Swal.close();
						Vue.prototype.$respuesta(vm, error, 3, true);
					});
			},
			getDetalle(id, seccion) {
				let options = Array();
				switch (seccion) {
					case "estado":
						options = this.estados;
						break;
				}
				console.log(options);
				if (id) {
					return options.find((o) => o.id == id).detalle;
				}
			},
		},
		mounted() {
			console.log("Componente " + getTitle() + " estado creado");
			let id = this.$route.params.id;
			console.log(id);

			this.editItem(id);
		},
	};
</script>
<style>
.container {
	margin-left: 0px;
	padding: 0px;
	max-width: 100% !important;
}
.icon-size {
	width: 80px !important;
	height: 80px !important;
}
.icon-size-2 {
	width: 50px !important;
	height: 50px !important;
}
.bg-gris {
	background-color: #eee;
}
</style>
